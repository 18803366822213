import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import features from '../features';
import {
  ApplyConfigToMWL,
  IDMWLConfig,
  ISLAOverride,
  IScreeningServiceEvent,
  IScreeningServiceIgnoreMessage,
} from '../interfaces';

// note: the default value here is mostly for tests. The tests provide a mock response for requests
// to the /v1/opsdash/screening-services endpoint, but it appears that a valid URL is still necessary
export const OPSDASH_API_URL =
  process.env.REACT_APP_OSDX_OPSDASH_API_URL || 'http://localhost:8000';

const timeout_ms = 30000;

const opsdashApiGet = <T>(
  token: string,
  path: string,
  config: AxiosRequestConfig = { timeout: timeout_ms }
): Promise<AxiosResponse<T>> => {
  if (features.auth0Enabled) {
    config.headers = { Authorization: `Bearer ${token}`, ...config.headers };
  }
  return axios.get<T>(path, {
    baseURL: OPSDASH_API_URL,
    ...config,
  });
};

const opsdashApiPost = <T>(
  token: string,
  path: string,
  newData: any,
  config: AxiosRequestConfig = { timeout: timeout_ms }
): Promise<AxiosResponse<T>> => {
  if (features.auth0Enabled) {
    config.headers = { Authorization: `Bearer ${token}`, ...config.headers };
  }
  return axios.post<T>(path, newData, {
    baseURL: OPSDASH_API_URL,
    ...config,
  });
};

const opsdashApiPut = <T>(
  token: string,
  path: string,
  newData: any,
  config: AxiosRequestConfig = { timeout: timeout_ms }
): Promise<AxiosResponse<T>> => {
  if (features.auth0Enabled) {
    config.headers = { Authorization: `Bearer ${token}`, ...config.headers };
  }
  return axios.put<T>(path, newData, {
    baseURL: OPSDASH_API_URL,
    ...config,
  });
};

const opsdashApiDelete = <T>(
  token: string,
  path: string,
  config: AxiosRequestConfig = { timeout: timeout_ms }
): Promise<AxiosResponse<T>> => {
  if (features.auth0Enabled) {
    config.headers = { Authorization: `Bearer ${token}`, ...config.headers };
  }
  return axios.delete<T>(path, {
    baseURL: OPSDASH_API_URL,
    ...config,
  });
};

const opsdashApiPatch = <T>(
  token: string,
  path: string,
  data: any,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  if (features.auth0Enabled) {
    config.headers = { Authorization: `Bearer ${token}`, ...config.headers };
  }
  return axios.patch<T>(path, data, {
    baseURL: OPSDASH_API_URL,
    ...config,
  });
};

export const fetchScreeningServiceEvents = (
  token: string,
  accessionNumber: string,
  eventIds: string
): Promise<IScreeningServiceEvent[]> => {
  return opsdashApiGet<IScreeningServiceEvent[]>(
    token,
    '/v1/opsdash/screening-services/events',
    {
      params: {
        include_ignored: 'True',
      },
      headers: {
        'Accession-Number': accessionNumber,
        'Event-Ids': eventIds,
      },
    }
  ).then((res) => res.data);
};

export const downloadInvoiceResults = (
  token: string,
  startDate: string,
  endDate: string,
  customer: string
): Promise<void> => {
  return opsdashApiGet<string>(token, '/v1/opsdash/invoices', {
    params: {
      start_finalized_date: startDate,
      end_finalized_date: endDate,
      customer,
    },
    responseType: 'blob',
  }).then((res) => {
    // approach inspired by: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
    const objectUrl = window.URL.createObjectURL(new Blob([res.data]));
    const tempLink = document.createElement('a');
    tempLink.setAttribute('href', objectUrl);
    tempLink.setAttribute(
      'download',
      `invoice-results-${startDate}-to-${endDate}-for-${customer}.csv`
    );
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  });
};

export const getDMWLConfigs = (token: string): Promise<IDMWLConfig[]> => {
  return opsdashApiGet<IDMWLConfig[]>( // GET request for fetch_mwl_calling_ae_config
    token,
    'v1/opsdash/dmwl/calling-ae-configs'
  ).then((res) => res.data);
};

export const addDMWLConfigs = (
  token: string,
  newData: IDMWLConfig
): Promise<AxiosResponse<IDMWLConfig[]>> => {
  return opsdashApiPost<IDMWLConfig[]>(
    token,
    'v1/opsdash/dmwl/calling-ae-configs',
    newData
  );
};

export const editDMWLConfigs = (
  token: string,
  newData: IDMWLConfig
): Promise<AxiosResponse<IDMWLConfig[]>> => {
  return opsdashApiPut<IDMWLConfig[]>(
    token,
    'v1/opsdash/dmwl/calling-ae-configs',
    newData
  );
};

export const deleteDMWLConfigs = (
  token: string,
  oldData: IDMWLConfig
): Promise<AxiosResponse<IDMWLConfig[]>> => {
  return opsdashApiDelete<IDMWLConfig[]>( // PUT request for add_mwl_calling_ae_config
    token,
    'v1/opsdash/dmwl/calling-ae-configs/' + oldData.applicationEntityId + '/'
  );
};

export const getAppliedDMWLConfigs = (
  token: string,
  applicationEntityId: string
): Promise<number> => {
  return opsdashApiGet<ApplyConfigToMWL>(
    token,
    'v1/opsdash/dmwl/calling-ae-configs/test_run/' + applicationEntityId + '/'
  ).then((res) => {
    return res.data.resultCount;
  });
};

export const updateEventsIgnoreMessage = (
  token: string,
  payload: IScreeningServiceIgnoreMessage
): Promise<number> => {
  return opsdashApiPatch<number>(
    token,
    '/v1/opsdash/screening-services/events/update_ignore_messages',
    payload
  ).then((res) => {
    return res.status;
  });
};

export const getSLAOverrides = (token: string): Promise<ISLAOverride[]> => {
  return opsdashApiGet<ISLAOverride[]>(token, 'v1/opsdash/sla-overrides').then(
    (res) => res.data
  );
};

export const addSLAOverride = (
  token: string,
  newData: ISLAOverride
): Promise<AxiosResponse<ISLAOverride>> => {
  return opsdashApiPost<ISLAOverride>(
    token,
    'v1/opsdash/sla-overrides/',
    newData
  );
};

export const editSLAOverride = (
  token: string,
  newData: ISLAOverride
): Promise<AxiosResponse<ISLAOverride>> => {
  return opsdashApiPut<ISLAOverride>(
    token,
    'v1/opsdash/sla-overrides/',
    newData
  );
};

export const deleteSLAOverride = (
  token: string,
  oldData: ISLAOverride
): Promise<AxiosResponse<ISLAOverride>> => {
  return opsdashApiDelete<ISLAOverride>(token, 'v1/opsdash/sla-overrides/', {
    headers: {
      'Accession-Number': oldData.accessionNumber,
    },
  });
};
