import React from 'react';
import MaterialTable, {
  Column,
  Options,
  MaterialTableProps,
} from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

import tableIcons from './Icons';

// NOTE: the generic types below extend 'object' to satisfy constraints imposed
// by material-table types. Aliasing 'object' because using it throws an eslint
// warning; just disable the warning once
// eslint-disable-next-line
type BaseObject = object;

export type IDataTableColumns<T extends BaseObject> = Array<Column<T>>;

export type IDataTableOptions<T extends BaseObject> = Options<T>;

export type IDataTableCellEditable<T extends BaseObject> =
  MaterialTableProps<T>['cellEditable'];

export type IDataTableEditable<T extends BaseObject> =
  MaterialTableProps<T>['editable'];

export type IDataTableActions<T extends BaseObject> =
  MaterialTableProps<T>['actions'];

export interface IDataTableProps<T extends BaseObject> {
  data: T[];
  columns: IDataTableColumns<T>;
  title: string;
  options?: IDataTableOptions<T>;
  exportOptions?: {
    csv?: {
      menuOptionText?: string;
      filename?: string;
    };
    pdf?: {
      menuOptionText?: string;
      filename?: string;
    };
  };
  cellEditable?: IDataTableCellEditable<T>;
  editable?: IDataTableEditable<T>;
  actions?: IDataTableActions<T>;
  isLoading: boolean;
}

// NOTE: not using an arrow function here because the generic type param <T> was
// ambiguous and being incorrectly interpreted as an opening JSX tag (I think):
// https://github.com/eslint/typescript-eslint-parser/issues/392
function DataTable<T extends BaseObject>({
  data,
  columns,
  title,
  options,
  exportOptions,
  cellEditable,
  editable,
  actions,
  isLoading,
}: IDataTableProps<T>): JSX.Element {
  if (!options) options = { grouping: true, columnsButton: true };
  if (exportOptions) {
    options.exportMenu = [];
    if (exportOptions.csv) {
      options.exportMenu.push({
        label: exportOptions.csv.menuOptionText || 'Export CSV',
        exportFunc: (cols, data) =>
          ExportCsv(
            cols,
            data,
            exportOptions?.csv?.filename || 'opsdash-table-export'
          ),
      });
    }
    if (exportOptions.pdf) {
      options.exportMenu.push({
        label: exportOptions.pdf.menuOptionText || 'Export PDF',
        exportFunc: (cols, data) =>
          ExportPdf(
            cols,
            data,
            exportOptions?.pdf?.filename || 'opsdash-table-export'
          ),
      });
    }
    options.exportAllData = true;
  }

  return (
    <MaterialTable
      columns={columns}
      data={data}
      title={title}
      icons={tableIcons}
      options={options}
      cellEditable={cellEditable}
      editable={editable}
      actions={actions}
      isLoading={isLoading}
    />
  );
}

export default DataTable;
