import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';

import Modal from '../Modal/Modal';
import DateRange from '../DateRange/DateRange';
import { getDefaultDateRange } from '../../utils/time';

import './InvoicingDownloadWidget.css';

interface IInvoicingParameterWidgetProps {
  setCustomer: (customer: string) => void;
  startDate: string;
  onChangeStartDate: (startDate: string) => void;
  endDate: string;
  onChangeEndDate: (endDate: string) => void;
}

const InvoicingParameterWidget = ({
  setCustomer,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
}: IInvoicingParameterWidgetProps): JSX.Element => {
  return (
    <>
      <div className="parameter-selection-container">
        <span>Search invoice by customer code: </span>
        <input
          className="search-customer"
          type="text"
          placeholder="Customer code..."
          onChange={(evt) => setCustomer(evt.target.value)}
        />
      </div>
      <span>An invalid customer code would result in an empty .csv file. </span>
      <div className="parameter-selection-container">
        <span>Date range (inclusive):</span>
        <DateRange
          startDate={startDate}
          endDate={endDate}
          onChangeStart={onChangeStartDate}
          onChangeEnd={onChangeEndDate}
        />
      </div>
    </>
  );
};

export interface IInvoicingDownloadWidgetProps {
  onSubmit: (
    startDate: string,
    endDate: string,
    customer: string
  ) => Promise<void>;
}

const InvoicingDownloadWidget = ({
  onSubmit,
}: IInvoicingDownloadWidgetProps): JSX.Element => {
  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    getDefaultDateRange();
  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);
  const [customer, setCustomer] = useState<string>('');
  const [showPhiModal, setShowPhiModal] = useState<boolean>(false);
  const [showParameterModal, setShowParameterModal] = useState<boolean>(false);
  const [parameterModalFooter, setParameterModalFooter] =
    useState<JSX.Element | null>(null);
  const [phiConfirmButtonDisabled, setPhiConfirmButtonDisabled] =
    useState<boolean>(false);

  return (
    <>
      <Button
        id="download-invoice-button"
        className="download-button"
        variant="primary"
        onClick={() => setShowParameterModal(true)}
        title="Download invoice"
      >
        Download Invoice Results
      </Button>
      <Modal
        show={showParameterModal}
        titleText="Invoicing parameters"
        body={
          <InvoicingParameterWidget
            setCustomer={setCustomer}
            startDate={startDate}
            onChangeStartDate={setStartDate}
            endDate={endDate}
            onChangeEndDate={setEndDate}
          />
        }
        cancelText="Cancel"
        confirmText="Continue"
        onCancel={() => setShowParameterModal(false)}
        onConfirm={() => {
          setParameterModalFooter(null);
          setShowParameterModal(false);
          setShowPhiModal(true);
        }}
        footerText={parameterModalFooter}
      />
      <Modal
        show={showPhiModal}
        titleText="PHI Warning"
        body="You are attempting to download content that contains PHI/PII onto your device. By continuing, you indicate you are aware of and will adhere to Imagen's PHI and ePHI Data Usage Policy."
        cancelText="Cancel"
        confirmText="Continue"
        onCancel={() => setShowPhiModal(false)}
        onConfirm={() => {
          setPhiConfirmButtonDisabled(true);
          onSubmit(startDate, endDate, customer)
            .then(() => {
              // on success, close the modal
              setParameterModalFooter(null);
              setPhiConfirmButtonDisabled(false);
              setShowPhiModal(false);
            })
            .catch(() => {
              // on errors, return to the parameter modal and show error message
              setParameterModalFooter(
                <span className="error">
                  Download failed, please try again.
                </span>
              );
              setShowPhiModal(false);
              setShowParameterModal(true);
              setPhiConfirmButtonDisabled(false);
            });
        }}
        confirmButtonDisabled={phiConfirmButtonDisabled}
      />
    </>
  );
};

export default InvoicingDownloadWidget;
