import React, { useEffect, useState } from 'react';
import { format } from 'date-fns-tz';
import DataTable, { IDataTableColumns } from '../DataTable/DataTable';
import { IDMWLConfig } from '../../interfaces';
import features from '../../features';
import { IconButton, Button, Collapse } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import SyncIcon from '@material-ui/icons/Sync';
import {
  addDMWLConfigs,
  editDMWLConfigs,
  deleteDMWLConfigs,
  getDMWLConfigs,
  getAppliedDMWLConfigs,
} from '../../api/opsdashApi';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from '@mui/material/Alert';
import CloseIcon from '@material-ui/icons/Close';

function renderDate(date_in: string | undefined | null): string {
  return date_in ? format(new Date(date_in), 'MM/dd/yyyy hh:mm:ss a zzz') : '';
}

function cleanInputs(data: IDMWLConfig): IDMWLConfig {
  data.modalityTypes = data.modalityTypes
    .map((m) => m.replace(/['‘’"“”]|\s/g, ''))
    .filter(Boolean);
  return data;
}

const columns: IDataTableColumns<IDMWLConfig> = [
  {
    title: 'Created At',
    field: 'createdAt',
    type: 'datetime',
    render: (rowData) => renderDate(rowData.createdAt),
    editable: 'never',
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    type: 'datetime',
    render: (rowData) => renderDate(rowData.updatedAt),
    editable: 'never',
  },
  {
    title: 'Application Entity',
    field: 'applicationEntity',
    tooltip: 'Field is required',
    validate: (rowData) =>
      !rowData.applicationEntity
        ? { isValid: false, helperText: 'Field is required' }
        : /[ ·.~`!@#$%\^&*+=\-\[\]\\\(\)\\';,/{}|\\":<>\?]/g.test(
            rowData.applicationEntity
          )
        ? { isValid: false, helperText: 'Invalid character' }
        : rowData.applicationEntity.length > 16
        ? {
            isValid: false,
            helperText: 'Field must be less than 16 characters',
          }
        : true,
    hiddenByColumnsButton: true,
  },
  {
    title: 'Customer',
    field: 'customer',
    tooltip: 'Field is required',
    validate: (rowData) =>
      !rowData.customer
        ? { isValid: false, helperText: 'Field is required' }
        : /[ ·.~`!@#$%\^&*+=\-\[\]\\\(\)\\';,/{}|\\":<>\?]/g.test(
            rowData.customer
          )
        ? { isValid: false, helperText: 'Invalid character' }
        : true,
    hiddenByColumnsButton: true,
  },
  {
    title: 'Modality Types',
    field: 'modalityTypes',
    render: (rowData) => rowData.modalityTypes.join(','),
    tooltip: `
    Field is required and can hold multiple items, separated by a comma (e.g.: X, Y, Z).
    \n\nSuggested values: {BD, CR, CT, DX, MG, MR, NR, OP, OT, OU, PR, SC, SD, US, XR}
    `,
    validate: (rowData) =>
      !rowData.modalityTypes
        ? { isValid: false, helperText: 'Field is required' }
        : true,
    hiddenByColumnsButton: true,
  },
  { title: 'Performing Location Name', field: 'performingLocation' },
  {
    title: 'Should Log to OpsDB',
    field: 'shouldLogToOpsdb',
    type: 'boolean',
    initialEditValue: 'true',
  },
  {
    title: 'Should Return SUID',
    field: 'shouldReturnSuid',
    type: 'boolean',
    initialEditValue: 'true',
  },
  {
    title: 'Application Entity ID',
    field: 'applicationEntityId',
    hidden: true,
    editable: 'never',
  },
  {
    title: 'Apply Config to MWL',
    field: 'applyConfigToMwl',
    editable: 'never',
  },
];

export interface IDMWLTableProps {
  data: IDMWLConfig[];
}

const DMWLTable = (): JSX.Element => {
  const [data, setDmwlConfigTable] = useState<IDMWLConfig[]>([]); //table data

  const [hadLoadError, setLoadError] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const { getAccessTokenSilently } = features.auth0Enabled
    ? useAuth0()
    : { getAccessTokenSilently: () => Promise.resolve('') };

  const [completeRequest, setCompleteRequest] = useState(false);
  const [successOutcomeMessage, setSuccessOutcomeMessage] =
    useState<string>('');
  const [openSuccess, setOpenSuccess] = React.useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [showEmptyDataSourceMessage, setShowEmptyDataSourceMessage] =
    useState(false);

  const updateDmwlConfigState = (token: string) => {
    return getDMWLConfigs(token)
      .then((result) => {
        setDmwlConfigTable(result);
        setLoadError('');
      })
      .catch((e) => setLoadError(e));
  };

  const addDmwlConfigState = (token: string, newData: IDMWLConfig) => {
    return addDMWLConfigs(token, cleanInputs(newData));
  };

  const editDmwlConfigState = (
    token: string,
    newData: IDMWLConfig,
    oldData: IDMWLConfig
  ) => {
    return editDMWLConfigs(token, cleanInputs(newData));
  };

  const deleteDmwlConfigState = (token: string, oldData: IDMWLConfig) => {
    return deleteDMWLConfigs(token, oldData);
  };

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
        return updateDmwlConfigState(token).then(() => {
          setIsLoading(false);
          setShowEmptyDataSourceMessage(true);
        });
      })
      .catch((e) => setLoadError(e));
  }, []);

  const addRow = (newData: IDMWLConfig, resolve: any) => {
    getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
        return addDmwlConfigState(accessToken, newData).then(() => {
          const dataToAdd = [...data, newData];
          setDmwlConfigTable(dataToAdd);
          updateDmwlConfigState(token);
          setLoadError('');
          setSuccessOutcomeMessage(
            'Success adding row for Application Entity = ' +
              newData.applicationEntity
          );
          resolve();
        });
      })
      .catch((e) => {
        setLoadError(e);
        resolve();
      });
    setCompleteRequest(true);
  };

  const applyConfig = (config: IDMWLConfig) => {
    let appliedCount: number | undefined = undefined;
    setIsLoading(true);

    getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
        return getAppliedDMWLConfigs(
          accessToken,
          config.applicationEntityId
        ).then((result) => {
          appliedCount = result;
          setLoadError('');
        });
      })
      .catch((e) => {
        let alertMessage: string;

        if (e.response.status === 404) {
          alertMessage = `The Application Entity ID ${config.applicationEntityId} was not found in the database (status code 404)`;
        } else if (e.response.status === 500) {
          alertMessage = `The backend was unable to establish a connection with the AE server ${config.applicationEntity} (status code 500)`;
        } else {
          alertMessage = `Encountered the following error while executing request:\n\n "${e}"`;
        }

        alert(
          alertMessage +
            '\n\nPlease share this error with the Engineering team for assistance.'
        );
      })
      .finally(() => {
        setDmwlConfigTable(
          data.map((el) =>
            el.applicationEntityId === config.applicationEntityId
              ? { ...el, applyConfigToMwl: appliedCount }
              : el
          )
        );
        setIsLoading(false);
      });
    setCompleteRequest(true);
  };

  const updateRow = (newData: IDMWLConfig, oldData: any, resolve: any) => {
    getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
        return editDmwlConfigState(accessToken, newData, oldData).then(() => {
          const dataUpdate = [...data];
          for (let index = 0; index < dataUpdate.length; index++) {
            if (
              dataUpdate[index].applicationEntityId ==
              oldData.applicationEntityId
            ) {
              dataUpdate[index] = newData;
              break;
            }
          }
          setDmwlConfigTable(dataUpdate);
          updateDmwlConfigState(token);
          setLoadError('');
          setSuccessOutcomeMessage(
            'Success updating row from (Application Entity, Customer, Modality Types, Performing Location, Should Log To OpsDB, Should Return SUID, Application Entity ID) = (' +
              oldData.applicationEntity +
              ', ' +
              oldData.customer +
              ', [' +
              oldData.modalityTypes +
              '], ' +
              oldData.performingLocation +
              ', ' +
              oldData.shouldLogToOpsdb +
              ', ' +
              oldData.shouldReturnSuid +
              ', ' +
              oldData.applicationEntityId +
              ') to (' +
              newData.applicationEntity +
              ', ' +
              newData.customer +
              ', [' +
              newData.modalityTypes +
              '], ' +
              newData.performingLocation +
              ', ' +
              newData.shouldLogToOpsdb +
              ', ' +
              newData.shouldReturnSuid +
              ',' +
              newData.applicationEntityId +
              ')'
          );
          resolve();
        });
      })
      .catch((e) => setLoadError(e));
    setCompleteRequest(true);
  };

  const deleteRow = (oldData: IDMWLConfig, resolve: any) => {
    getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
        return deleteDmwlConfigState(accessToken, oldData).then(() => {
          const dataDelete = [...data];
          for (let index = 0; index < dataDelete.length; index++) {
            if (
              dataDelete[index].applicationEntityId ==
              oldData.applicationEntityId
            ) {
              dataDelete.splice(index, 1);
              break;
            }
          }
          setDmwlConfigTable(dataDelete);
          updateDmwlConfigState(token);
          setLoadError('');
          setSuccessOutcomeMessage(
            'Success deleting row for (Application Entity, Application Entity ID) = (' +
              oldData.applicationEntity +
              ', ' +
              oldData.applicationEntityId +
              ')'
          );
          resolve();
        });
      })
      .catch((e) => setLoadError(e));
    setCompleteRequest(true);
  };

  if (hadLoadError != '') {
    return (
      <div>
        <Typography>
          Encountered the following error while executing request:
        </Typography>
        <Typography color="secondary">{String(hadLoadError)}</Typography>
        <Typography>
          {String(hadLoadError).includes('timeout')
            ? 'Please check your connection (e.g.: are you using a VPN?) and try again. If this issue persists, please contact the Engineering team for assistance.'
            : 'Please share this error with the Engineering team for assistance.'}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <div>
        {completeRequest && successOutcomeMessage != '' && (
          <Box>
            <Collapse in={openSuccess}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenSuccess(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{successOutcomeMessage}</strong>
              </Alert>
            </Collapse>
            <p></p>
            <Button
              disabled={openSuccess}
              variant="outlined"
              onClick={() => {
                setOpenSuccess(true);
              }}
            >
              Reopen message
            </Button>
          </Box>
        )}
      </div>
      <DataTable<IDMWLConfig>
        columns={columns}
        data={data}
        title="DICOM Modality Worklist Configuration Table"
        isLoading={isLoading}
        options={{ showEmptyDataSourceMessage }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              // When adding a column that is backed by an array, material-table returns a string, so it must be converted back to string[]
              newData.modalityTypes = newData.modalityTypes
                .toString()
                .split(',');
              addRow(newData, resolve);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              // When editing a column that is backed by an array, material-table returns a string, so it must be converted back to string[]
              newData.modalityTypes = newData.modalityTypes
                .toString()
                .split(',');
              updateRow(newData, oldData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              deleteRow(oldData, resolve);
            }),
        }}
        actions={[
          {
            icon: SyncIcon,
            tooltip: 'Apply Config to MWL',
            onClick: (event, rowData: any) => {
              applyConfig(rowData);
            },
          },
        ]}
      />
    </div>
  );
};

export default DMWLTable;
