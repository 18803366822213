import React from 'react';

import RBModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export interface IModalProps {
  show: boolean;
  titleText?: string;
  body?: JSX.Element | string;
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm?: () => void;
  footerText?: JSX.Element | string | null;
  confirmButtonDisabled?: boolean;
}

const Modal = ({
  show,
  titleText,
  body,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  footerText,
  confirmButtonDisabled,
}: IModalProps): JSX.Element => (
  <RBModal show={show} onHide={onCancel}>
    {titleText && (
      <RBModal.Header closeButton>
        <RBModal.Title>{titleText}</RBModal.Title>
      </RBModal.Header>
    )}
    {body && <RBModal.Body>{body}</RBModal.Body>}
    <RBModal.Footer>
      {footerText}
      <Button variant="secondary" onClick={onCancel}>
        {cancelText || 'Close'}
      </Button>
      {confirmText && (
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={!!confirmButtonDisabled}
        >
          {confirmText}
        </Button>
      )}
    </RBModal.Footer>
  </RBModal>
);

export default Modal;
