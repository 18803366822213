import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

// returns a date range ending today and starting 6 days ago
// the dates are represented in string YYYY-MM-DD format (matching
// what an HTML date input expects)
// note: 6 days ago assumes the dates will be used as an inclusive week-long range
export const getDefaultDateRange = (): {
  startDate: string;
  endDate: string;
} => {
  const date = new Date(); // today
  const endDate = format(date, 'yyyy-MM-dd');
  date.setDate(date.getDate() - 6); // last week
  const startDate = format(date, 'yyyy-MM-dd');
  return { startDate, endDate };
};

// splits the [startDate, endDate] (inclusive) date range into disjoint sub-ranges (each spanning
// splitLength days)
// startDate and endDate are in yyyy-MM-dd format
export const splitDateRange = (
  startDate: string,
  endDate: string,
  splitLength: number
): string[][] => {
  const ranges: string[][] = [];

  const rangeStart = new Date(startDate);
  rangeStart.setUTCHours(2); // avoid issues with daylight savings time shifting us earlier than midnight into previous day
  const rangeEnd = new Date(startDate);
  rangeEnd.setUTCHours(2);
  rangeEnd.setDate(rangeEnd.getDate() + (splitLength - 1)); // -1 since it's an inclusive range
  const end = new Date(endDate);

  while (rangeEnd < end) {
    // UTC NOTE: timezone doesn't matter here, since the input startDate and endDate are
    // just yyyy-MM-dd; just want to make sure local tz isn't influencing the formatting
    // by pushing us back to an earlier day
    ranges.push([
      formatInTimeZone(rangeStart, 'UTC', 'yyyy-MM-dd'),
      formatInTimeZone(rangeEnd, 'UTC', 'yyyy-MM-dd'),
    ]);
    rangeStart.setDate(rangeStart.getDate() + splitLength);
    rangeEnd.setDate(rangeEnd.getDate() + splitLength);
  }
  ranges.push([
    formatInTimeZone(rangeStart, 'UTC', 'yyyy-MM-dd'),
    formatInTimeZone(end, 'UTC', 'yyyy-MM-dd'),
  ]);

  return ranges;
};
