import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import './Dashboard.css';

import InvoicingArea from '../InvoicingArea/InvoicingArea';
import EventSearch from '../EventSearch/EventSearch';
import features from '../../features';
import { downloadInvoiceResults } from '../../api/opsdashApi';

// Dashboard functional component
const Dashboard = (): JSX.Element => {
  const [hadLoadError, setHadLoadError] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>('');

  const { getAccessTokenSilently } = features.auth0Enabled
    ? useAuth0()
    : { getAccessTokenSilently: () => Promise.resolve('') };

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
      })
      .catch(() => setHadLoadError(true));
  }, []);

  const onInvoiceDownloadSubmit = (
    startDate: string,
    endDate: string,
    customer: string
  ): Promise<void> => {
    return downloadInvoiceResults(accessToken, startDate, endDate, customer);
  };

  if (hadLoadError) {
    return (
      <div>
        Oops, encountered an error loading screening service data. Please try
        again or contact Omniscreen team.
      </div>
    );
  }
  return (
    <div className="dashboard">
      {features.invoicingDownloadEnabled && (
        <InvoicingArea onDownloadSubmit={onInvoiceDownloadSubmit} />
      )}
      <hr />
      {features.eventSearchEnabled && (
        <div key="event-search-area" className="display-section">
          <EventSearch />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
