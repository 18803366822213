import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Event, Assignment, Edit } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import HeaderAndSidebar from './components/HeaderAndSidebar/HeaderAndSidebar';
import Footer from './components/Footer/Footer';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import features from './features';
import DMWLTable from './components/DMWLConfigTable/DMWLConfigTable';
import SLAOverridesTable from './components/SLAOverrides/SLAOverrides';
import Dashboard from './components/Dashboard/Dashboard';

const theme = createTheme({
  palette: {
    primary: {
      main: '#343a40',
    },
  },
});

const SKIP_AUTH0_CONFIG: Pick<
  Auth0ContextInterface,
  'isLoading' | 'isAuthenticated' | 'loginWithRedirect' | 'logout'
> = {
  isLoading: false,
  isAuthenticated: true,
  loginWithRedirect: async () => {
    return;
  },
  logout: async () => {
    return;
  },
};

const App = (): JSX.Element | null => {
  const { isLoading, isAuthenticated, loginWithRedirect, logout } =
    features.auth0Enabled ? useAuth0() : SKIP_AUTH0_CONFIG;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <div className="app-container">
          <HeaderAndSidebar
            useLogout={features.auth0Enabled}
            logout={logout}
            sidebarLinks={[
              {
                text: 'Screening Services',
                path: '/screening-services',
                icon: <Event />,
              },
              {
                text: 'DMWL Config',
                path: '/dmwl-config',
                icon: <Assignment />,
              },
              {
                text: 'SLA Overrides',
                path: '/sla-overrides',
                icon: <Edit />,
              },
            ]}
            content={
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/screening-services" />}
                />
                <Route path="/screening-services" element={<Dashboard />} />
                <Route path="/dmwl-config" element={<DMWLTable />} />
                <Route path="/sla-overrides" element={<SLAOverridesTable />} />
              </Routes>
            }
          />
          <Footer />
        </div>
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;
