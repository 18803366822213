import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import features from './features';
import { Auth0Provider } from '@auth0/auth0-react';

if (!features.auth0Enabled) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <Auth0Provider
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      domain={process.env.REACT_APP_OSDX_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_OSDX_OPSDASH_UI_CLIENT_ID!}
      audience={process.env.REACT_APP_OSDX_OPSDASH_API_AUDIENCE!}
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
      redirectUri={window.location.href}
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
