import React from 'react';
import './HeaderAndSidebar.css';
import imagen_logo from '../../assets/imagen_logo.svg';
import clsx from 'clsx';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Menu, ChevronLeft } from '@material-ui/icons';
import {
  ListItemIcon,
  MenuItem,
  Typography,
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  Button,
  Grid,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';

const Logo = () => {
  return (
    <div className="logo">
      <img src={imagen_logo} alt="logo" />
    </div>
  );
};

const LogoutButton = (props: {
  logout: Auth0ContextInterface['logout'];
}): JSX.Element => {
  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={() => props.logout({ returnTo: window.location.href })}
    >
      Logout
    </Button>
  );
};

// Adapted from https://v4.mui.com/components/drawers/#persistent-drawer
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    width: 0, // necessary for responsive sizing
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export interface IHeaderAndSidebarProps {
  useLogout: boolean;
  logout: Auth0ContextInterface['logout'];
  sidebarLinks: Array<{ text: string; path: string; icon: React.ReactElement }>;
  content: React.ReactElement;
}

const HeaderAndSidebar = (props: IHeaderAndSidebarProps): JSX.Element => {
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={3}>
              <IconButton
                aria-label="open-sidebar"
                color="inherit"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <Menu />
              </IconButton>
              <Logo />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" align="center">
                Care Delivery Operational Dashboard
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="right">
                {props.useLogout && <LogoutButton logout={props.logout} />}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton aria-label="close-sidebar" onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          {props.sidebarLinks.map(({ text, path, icon }) => (
            <MenuItem
              key={path}
              button
              component={Link}
              to={path}
              selected={location.pathname === path}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </MenuItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Toolbar /* this shifts content down so it appears below the AppBar*/ />
        {props.content}
      </main>
    </div>
  );
};

export default HeaderAndSidebar;
