import React from 'react';
import './DateRange.css';

export interface IDateRangeProps {
  startDate: string;
  endDate: string;
  onChangeStart: (startValue: string) => void;
  onChangeEnd: (endValue: string) => void;
}

const DateRange = ({
  startDate,
  endDate,
  onChangeStart,
  onChangeEnd,
}: IDateRangeProps): JSX.Element => {
  return (
    <div className="date-range">
      <input
        className="date-picker"
        type="date"
        value={startDate}
        max={endDate}
        onChange={(evt) => onChangeStart(evt.target.value)}
      />
      -
      <input
        className="date-picker"
        type="date"
        value={endDate}
        min={startDate}
        onChange={(evt) => onChangeEnd(evt.target.value)}
      />
    </div>
  );
};

export default DateRange;
