import React, { useState } from 'react';

import { parse, ParseResult } from 'papaparse';

import Button from 'react-bootstrap/Button';
import Modal from '../Modal/Modal';

import './InvoicingDiscrepancyWidget.css';

interface CSVRow {
  [field: string]: string;
}

type CSVData = CSVRow[];

interface NormalizedCsvRow {
  accessionNumber: string;
  procedureCode: string;
  serviceType: string;
}
type NormalizedCsvData = NormalizedCsvRow[];

export const parseCsv = (
  file: File | null,
  expectedColumns: string[]
): Promise<CSVData | null> => {
  const promise = new Promise<CSVData | null>((resolve, reject) => {
    if (!file) return resolve(null);
    parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results: ParseResult<CSVRow>) => {
        for (let i = 0; i < expectedColumns.length; i++) {
          if (
            !results?.meta?.fields ||
            results.meta.fields.indexOf(expectedColumns[i]) < 0
          ) {
            return reject(`Missing expected column ${expectedColumns[i]}`);
          }
        }
        return resolve(results.data);
      },
    });
  });

  return promise;
};

interface ICSVFileSelectorProps {
  inputId: string;
  labelText: string;
  expectedColumns: string[];
  onSelectCsv: (data: CSVData | null) => void;
}

const CSVFileSelector = ({
  inputId,
  labelText,
  expectedColumns,
  onSelectCsv,
}: ICSVFileSelectorProps): JSX.Element => {
  const [csvError, setCsvError] = useState<string | null>(null);

  return (
    <div className="csv-selection-container">
      <label className="section-label" htmlFor={inputId}>
        {labelText}
      </label>
      <input
        type="file"
        name={inputId}
        id={inputId}
        accept=".csv"
        onChange={(evt) =>
          parseCsv(evt?.target?.files?.item(0) || null, expectedColumns)
            .then((results) => {
              onSelectCsv(results);
              setCsvError(null);
            })
            .catch((errStr) => {
              onSelectCsv(null);
              setCsvError(errStr);
            })
        }
      />
      <div className={`selection-notes ${csvError ? 'error' : ''}`}>
        (Expects columns named: {expectedColumns.join(', ')})
      </div>
    </div>
  );
};

interface IConfigWidgetProps {
  onSelectInvoicingCsv: (data: CSVData | null) => void;
  onSelectEradCsv: (data: CSVData | null) => void;
  modalityMapping: string;
  onChangeModalityMapping: (mapping: string) => void;
}

// used in body of first modal stage
const ConfigWidget = ({
  onSelectInvoicingCsv,
  onSelectEradCsv,
  modalityMapping,
  onChangeModalityMapping,
}: IConfigWidgetProps): JSX.Element => {
  return (
    <>
      <CSVFileSelector
        inputId="invoicing-csv"
        labelText="Invoicing CSV:"
        expectedColumns={['accession_number', 'service_type', 'procedure_code']}
        onSelectCsv={onSelectInvoicingCsv}
      />
      <hr />
      <CSVFileSelector
        inputId="erad-csv"
        labelText="eRad CSV:"
        expectedColumns={['Accession No.', 'Modality', 'Procedure Code']}
        onSelectCsv={onSelectEradCsv}
      />
      <hr />
      <div className="modality-service-type-mapping">
        <div className="section-label">Modality to Service Type mapping:</div>
        <div
          className={`selection-notes ${
            !parseModalityMapping(modalityMapping) ? 'error' : ''
          }`}
        >
          (Each line should contain a modality and a service type separated by a
          comma)
        </div>
        <textarea
          id="modality-map-textarea"
          rows={6}
          cols={40}
          placeholder="DX,XR ..."
          onChange={(evt) => onChangeModalityMapping(evt.target.value)}
          value={modalityMapping}
        />
      </div>
    </>
  );
};

// finds rows in the first csv that don't exist in the second
// note: probably faster ways to do this; if we run into performance
// issues we could go for something smarter
export const computeLeftDiff = (
  csv1: NormalizedCsvData,
  csv2: NormalizedCsvData
): string[] => {
  const leftDiff = [];
  const rightSet: { [key: string]: { [key: string]: Set<string> } } = {};
  csv2.reduce((ret, { accessionNumber, procedureCode, serviceType }) => {
    if (!ret[accessionNumber]) ret[accessionNumber] = {};
    if (!ret[accessionNumber][procedureCode])
      ret[accessionNumber][procedureCode] = new Set();
    ret[accessionNumber][procedureCode].add(serviceType);
    return ret;
  }, rightSet);

  for (let i = 0; i < csv1.length; i++) {
    const { accessionNumber, procedureCode, serviceType } = csv1[i];
    if (!rightSet?.[accessionNumber]?.[procedureCode]?.has(serviceType)) {
      leftDiff.push(`${accessionNumber}, ${serviceType}, ${procedureCode}`);
    }
  }

  // also double-check that the left set doesn't have duplicate accession numbers,
  // since that could mean that we're double-billing a service or that something is
  // awry in eRad. Never really expect this to be the case
  const accnoSet = new Set(csv1.map((row) => row.accessionNumber));
  if (accnoSet.size !== csv1.length) {
    // this warning could be more helpful...but this shouldn't be shown often, if at all
    leftDiff.push('Warning: this csv contains duplicate accession numbers!');
  }
  return leftDiff;
};

export const computeDiffs = (
  invoicingCsv: CSVData,
  eradCsv: CSVData,
  parsedModalityMapping: ParsedModalityMapping
): { invoicer: string[]; erad: string[] } => {
  const normalizedInvoicingCsv: NormalizedCsvData = invoicingCsv.map((row) => {
    return {
      accessionNumber: row.accession_number,
      procedureCode: row.procedure_code,
      serviceType: row.service_type,
    };
  });

  const normalizedEradCsv: NormalizedCsvData = eradCsv.map((row) => {
    return {
      accessionNumber: row['Accession No.'],
      procedureCode: row['Procedure Code'],
      serviceType: parsedModalityMapping[row['Modality']] || row['Modality'],
    };
  });

  const presentInInvoicerOnly = computeLeftDiff(
    normalizedInvoicingCsv,
    normalizedEradCsv
  );
  const presentInEradOnly = computeLeftDiff(
    normalizedEradCsv,
    normalizedInvoicingCsv
  );

  return { invoicer: presentInInvoicerOnly, erad: presentInEradOnly };
};

interface IDiffWidgetProps {
  invoicingCsv: CSVData | null;
  eradCsv: CSVData | null;
  parsedModalityMapping: ParsedModalityMapping | null;
  visible: boolean;
}

const DiffWidget = ({
  invoicingCsv,
  eradCsv,
  parsedModalityMapping,
  visible,
}: IDiffWidgetProps): JSX.Element => {
  if (!visible) return <div></div>;
  if (!invoicingCsv || !eradCsv || !parsedModalityMapping)
    return <div>Parameters invalid</div>;

  const diffs = computeDiffs(invoicingCsv, eradCsv, parsedModalityMapping);

  return (
    <div>
      <div key="invoicer">
        <h5>Only present in invoicer results:</h5>
        <textarea
          rows={6}
          cols={50}
          placeholder="None!"
          readOnly={true}
          value={diffs.invoicer.join('\n')}
        />
      </div>
      <div key="erad">
        <h5>Only present in eRad export:</h5>
        <textarea
          rows={6}
          cols={50}
          placeholder="None!"
          readOnly={true}
          value={diffs.erad.join('\n')}
        />
      </div>
    </div>
  );
};

interface ParsedModalityMapping {
  [key: string]: string;
}

// mapping should look like:
//   MOD1,ST1
//   MOD2,ST2
// (newline-separated maps, comma-separated modalities and service types)
export const parseModalityMapping = (
  modalityMapping: string
): ParsedModalityMapping | null => {
  const parsedModalityMapping: ParsedModalityMapping = {};
  const lines = modalityMapping.split('\n');
  for (let i = 0; i < lines.length; i++) {
    if (!lines[i]) continue;
    const pieces = lines[i].split(',');
    if (pieces.length !== 2) return null;
    if (!pieces[0] || !pieces[1]) return null;
    parsedModalityMapping[pieces[0].trim()] = pieces[1].trim();
  }
  return parsedModalityMapping;
};

// exported component
const InvoicingDiscrepancyWidget = (): JSX.Element => {
  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
  const [showDiffModal, setShowDiffModal] = useState<boolean>(false);
  const [invoicingCsv, setInvoicingCsv] = useState<CSVData | null>(null);
  const [eradCsv, setEradCsv] = useState<CSVData | null>(null);
  const [modalityMapping, setModalityMapping] = useState<string>('');

  const parsedModalityMapping = parseModalityMapping(modalityMapping);

  const configValid = !!invoicingCsv && !!eradCsv && !!parsedModalityMapping;

  return (
    <>
      <Button
        id="identify-discrepancies-button"
        className="identify-discrepancies-button"
        variant="secondary"
        onClick={() => setShowConfigModal(true)}
        title="Identify invoicing discrepancies"
      >
        Identify Invoicing Discrepancies
      </Button>
      <Modal
        show={showConfigModal}
        titleText="Invoicing discrepancy identifier: select files"
        body={
          <ConfigWidget
            onSelectInvoicingCsv={setInvoicingCsv}
            onSelectEradCsv={setEradCsv}
            modalityMapping={modalityMapping}
            onChangeModalityMapping={setModalityMapping}
          />
        }
        cancelText="Cancel"
        confirmText="Find Discrepancies"
        onCancel={() => setShowConfigModal(false)}
        onConfirm={() => {
          setShowConfigModal(false);
          setShowDiffModal(true);
        }}
        confirmButtonDisabled={!configValid}
      />
      <Modal
        show={showDiffModal}
        titleText="Invoicing discrepancy identifier: diffs"
        body={
          <DiffWidget
            invoicingCsv={invoicingCsv}
            eradCsv={eradCsv}
            parsedModalityMapping={parsedModalityMapping}
            visible={showDiffModal}
          />
        }
        cancelText="Close"
        onCancel={() => setShowDiffModal(false)}
      />
    </>
  );
};

export default InvoicingDiscrepancyWidget;
