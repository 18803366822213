interface IFeatureFlagDictionary {
  auth0Enabled: boolean;
  invoicingDownloadEnabled: boolean;
  eventSearchEnabled: boolean;
  invoicingDiscrepancyEnabled: boolean;
}

const features: IFeatureFlagDictionary = {
  auth0Enabled:
    (
      process.env.REACT_APP_FEATURE_OSDX_OPSDASH_UI_AUTH0_ENABLED || 'true'
    ).toLowerCase() !== 'false', // defaults enabled
  invoicingDownloadEnabled:
    (
      process.env
        .REACT_APP_FEATURE_OSDX_OPSDASH_UI_INVOICING_DOWNLOAD_ENABLED || 'false'
    ).toLowerCase() === 'true',
  eventSearchEnabled:
    (
      process.env.REACT_APP_FEATURE_OSDX_OPSDASH_UI_EVENT_SEARCH_ENABLED ||
      'false'
    ).toLowerCase() === 'true',
  invoicingDiscrepancyEnabled:
    (
      process.env
        .REACT_APP_FEATURE_OSDX_OPSDASH_UI_INVOICING_DISCREPANCY_ENABLED ||
      'false'
    ).toLowerCase() === 'true',
};

export default features;
