import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Input,
} from '@material-ui/core';
import axios from 'axios';

import features from '../../features';
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused';
import { IScreeningServiceEvent } from '../../interfaces';
import DataTable, { IDataTableColumns } from '../DataTable/DataTable';
import {
  fetchScreeningServiceEvents,
  updateEventsIgnoreMessage,
} from '../../api/opsdashApi';

// SEARCH BOX
export interface IEventSearchInputWidgetProps {
  onSubmit: (accessionNumber: string, eventIds: string) => Promise<void>;
}

const EventSearchInputWidget = ({
  onSubmit,
}: IEventSearchInputWidgetProps): JSX.Element => {
  const [accessionNumber, setAccessionNumber] = useState<string>('');
  const [eventIds, setEventIds] = useState<string>('');

  const canSubmit = !!accessionNumber || !!eventIds;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h4">Search OpsDB Events</Typography>
      </Grid>
      <Grid item>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(accessionNumber, eventIds);
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <FormControl>
                <InputLabel htmlFor="accession-number">
                  Accession Number
                </InputLabel>
                <Input
                  type="input"
                  id="accession-number"
                  value={accessionNumber}
                  onChange={(evt) => setAccessionNumber(evt.target.value)}
                  disabled={!!eventIds}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel htmlFor="event-ids">Event IDs</InputLabel>
                <Input
                  type="input"
                  id="event-ids"
                  value={eventIds}
                  onChange={(evt) => setEventIds(evt.target.value)}
                  disabled={!!accessionNumber}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                id="search-events-button"
                type="submit"
                variant="contained"
                disabled={!canSubmit}
              >
                Search OpsDB
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

// TABLE
const columns: IDataTableColumns<IScreeningServiceEvent> = [
  { title: 'Event ID', field: 'eventId' },
  {
    title: 'Created At',
    field: 'createdAt',
    type: 'date',
    defaultSort: 'desc',
  },
  { title: 'Updated At', field: 'updatedAt' },
  { title: 'Customer', field: 'customer' },
  { title: 'Event Type', field: 'eventType' },
  { title: 'Accession Number', field: 'accessionNumber' },
  {
    title: 'Ignored',
    field: 'ignoreMessage',
    type: 'boolean',
  },
  { title: 'MRN', field: 'mrn' },
  { title: 'Finalized At', field: 'finalizedAt' },
  { title: 'Service Type', field: 'serviceType' },
  { title: 'Procedure Code', field: 'procedureCode' },
  {
    title: 'HL7 JSON',
    field: 'dataJson',
    hidden: true,
    render: (rowData) => JSON.stringify(rowData.dataJson, null, 2),
  },
];

interface IEventSearchTableProps {
  accessionNumber?: string;
  eventIds?: string;
  events: IScreeningServiceEvent[];
  onUpdateEvents?: any;
}

const EventSearchTable = ({
  events,
  accessionNumber,
  eventIds,
  onUpdateEvents,
}: IEventSearchTableProps): JSX.Element => {
  const title =
    !!accessionNumber || !!eventIds
      ? `Results for ${
          !!accessionNumber
            ? `accession number: "${accessionNumber}"`
            : `event IDs: "${eventIds}"`
        }`
      : 'Enter an accession number or a comma-delimited list of event IDs.';

  const getAccessTokenSilently = features.auth0Enabled
    ? useAuth0().getAccessTokenSilently
    : () => Promise.resolve('');

  const updateEventsIgnoreMessageStatus = async (
    eventIds: number[],
    ignoreMessage: boolean
  ) => {
    const token = await getAccessTokenSilently();
    return updateEventsIgnoreMessage(token, {
      eventIds: eventIds,
      ignoreMessage: ignoreMessage,
    }).then(() => {
      const newEvents = events.map((event) => {
        if (eventIds.indexOf(event.eventId) !== -1) {
          event.ignoreMessage = ignoreMessage;
        }
        return event;
      });
      onUpdateEvents(newEvents);
    });
  };

  return (
    <DataTable<IScreeningServiceEvent>
      columns={columns}
      data={events}
      title={title}
      isLoading={false}
      options={{
        // highlight ignored messages in table
        rowStyle: (rowData) => ({
          backgroundColor: rowData.ignoreMessage ? '#ffdddd' : '',
        }),
        grouping: true,
        columnsButton: true,
        selection: true,
      }}
      cellEditable={{
        onCellEditApproved: async (newValue, oldValue, rowData, columnDef) => {
          if (columnDef.field === 'ignoreMessage' && oldValue !== newValue) {
            try {
              await updateEventsIgnoreMessageStatus(
                [rowData.eventId],
                newValue
              );
            } catch (e) {
              alert(`Error: ${e.message}`);
            }
          }
        },
        isCellEditable: (rowData, columnDef) => {
          return columnDef.field === 'ignoreMessage';
        },
      }}
      actions={[
        {
          tooltip: 'Ignore All Selected Events',
          icon: NotificationsPausedIcon,
          onClick: async (event, rowData: any) => {
            alert(rowData.length + ' event(s) will be ignored.');
            const eventIds = rowData.map(
              (event: IScreeningServiceEvent) => event.eventId
            );
            try {
              await updateEventsIgnoreMessageStatus(eventIds, true);
            } catch (e) {
              alert(`Error: ${e.message}`);
            }
          },
        },
      ]}
    />
  );
};

// FULL COMPONENT
const EventSearch = (): JSX.Element => {
  const [accno, setAccno] = useState<string>('');
  const [eventIds, setEventIds] = useState<string>('');
  const [events, setEvents] = useState<IScreeningServiceEvent[]>([]);
  const [error, setError] = useState<string>('');

  const getAccessTokenSilently = features.auth0Enabled
    ? useAuth0().getAccessTokenSilently
    : () => Promise.resolve('');

  const onSubmit = async (accessionNumber: string, eventIds: string) => {
    setError('');
    const token = await getAccessTokenSilently();
    try {
      const screeningServiceEvents = await fetchScreeningServiceEvents(
        token,
        accessionNumber,
        eventIds
      );
      setEvents(screeningServiceEvents);
      setAccno(accessionNumber);
      setEventIds(eventIds);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 422) {
          const data: any = e.response?.data;
          setError(`Error: ${data.detail}`);
        } else {
          setError(`Error: ${e.message}`);
        }
      } else {
        setError(`Encountered an unexpected error.`);
      }
    }
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <EventSearchInputWidget onSubmit={onSubmit} />
      </Grid>
      {!!error && (
        <Grid item>
          <Typography variant="subtitle2" color="secondary">
            {error}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <EventSearchTable
          accessionNumber={accno}
          eventIds={eventIds}
          events={events}
          onUpdateEvents={setEvents}
        />
      </Grid>
    </Grid>
  );
};

export default EventSearch;
