import React, { useEffect, useState } from 'react';
import { format } from 'date-fns-tz';
import features from '../../features';
import { useAuth0 } from '@auth0/auth0-react';
import {
  addSLAOverride,
  editSLAOverride,
  deleteSLAOverride,
  getSLAOverrides,
} from '../../api/opsdashApi';
import Typography from '@material-ui/core/Typography';
import DataTable, { IDataTableColumns } from '../DataTable/DataTable';
import { ISLAOverride } from '../../interfaces';

function renderDate(date_in: string | undefined | null): string {
  return date_in ? format(new Date(date_in), 'MM/dd/yyyy hh:mm:ss a zzz') : '';
}

const columns: IDataTableColumns<ISLAOverride> = [
  {
    title: 'Created At',
    field: 'createdAt',
    type: 'datetime',
    render: (rowData) => renderDate(rowData.createdAt),
    editable: 'never',
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    type: 'datetime',
    render: (rowData) => renderDate(rowData.updatedAt),
    editable: 'never',
  },
  {
    title: 'Accession Number',
    type: 'string',
    field: 'accessionNumber',
    editable: 'onAdd',
    validate: (rowData) =>
      !rowData.accessionNumber
        ? { isValid: false, helperText: 'Field is required' }
        : true,
  },
  {
    title: 'Meets SLA',
    field: 'withinCompliance',
    lookup: { true: 'True', false: 'False' },
    initialEditValue: true,
  },
  {
    title: 'Omit from reports',
    field: 'ignore',
    lookup: { true: 'True', false: 'False' },
    initialEditValue: false,
  },
  { title: 'Comments', type: 'string', field: 'comments' },
];

const SLAOverridesTable = (): JSX.Element => {
  const [slaOverrideData, setSLAOverrideData] = useState<ISLAOverride[]>([]);
  const [loadError, setLoadError] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [showEmptyDataSourceMessage, setShowEmptyDataSourceMessage] =
    useState(false);

  const { getAccessTokenSilently } = features.auth0Enabled
    ? useAuth0()
    : { getAccessTokenSilently: () => Promise.resolve('') };

  const updateSLAOverrideState = (token: string) => {
    return getSLAOverrides(token)
      .then((result) => {
        setSLAOverrideData(result);
        setLoadError('');
      })
      .catch((e) => setLoadError(e));
  };

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
        return updateSLAOverrideState(token).then(() => {
          setIsLoading(false);
          setShowEmptyDataSourceMessage(true);
        });
      })
      .catch((e) => setLoadError(e));
  }, []);

  if (loadError != '') {
    return (
      <div>
        <Typography>
          Encountered the following error fetching SLA Overrides data:
        </Typography>
        <Typography color="secondary">{String(loadError)}</Typography>
        <Typography>
          {String(loadError).includes('timeout')
            ? 'Please check your connection (e.g.: are you using a VPN?) and try again. If this issue persists, please contact the Engineering team for assistance.'
            : 'Please share this error with the Engineering team for assistance.'}
        </Typography>
      </div>
    );
  }

  function mapBackendDataToInterfaceData(
    backendSLAOverrideData: any
  ): ISLAOverride {
    return {
      createdAt: backendSLAOverrideData.created_at,
      updatedAt: backendSLAOverrideData.updated_at,
      accessionNumber: backendSLAOverrideData.accession_number,
      withinCompliance: backendSLAOverrideData.within_compliance,
      ignore: backendSLAOverrideData.ignore,
      comments: backendSLAOverrideData.comments,
    };
  }

  const addRow = (newSLAOverrideData: ISLAOverride) => {
    return addSLAOverride(accessToken, newSLAOverrideData).then((response) => {
      const dataToAdd = [...slaOverrideData, response.data];
      setSLAOverrideData(dataToAdd);
      setLoadError('');
    });
  };

  const updateRow = (
    newSLAOverrideData: ISLAOverride,
    oldSLAOverrideData: any
  ) => {
    if (oldSLAOverrideData === newSLAOverrideData) {
      return Promise.reject();
    }
    return editSLAOverride(accessToken, newSLAOverrideData).then((response) => {
      const slaOverrideUpdate = mapBackendDataToInterfaceData(response.data);
      const dataUpdate = [...slaOverrideData].map((sla) => {
        if (sla.accessionNumber === slaOverrideUpdate.accessionNumber) {
          sla = slaOverrideUpdate;
        }
        return sla;
      });
      setSLAOverrideData(dataUpdate);
      setLoadError('');
    });
  };

  const deleteRow = (oldSLAOverrideData: ISLAOverride) => {
    return deleteSLAOverride(accessToken, oldSLAOverrideData).then(() => {
      const dataDelete = [...slaOverrideData].filter(
        (item) => item.accessionNumber !== oldSLAOverrideData.accessionNumber
      );
      setSLAOverrideData(dataDelete);
      setLoadError('');
    });
  };

  return (
    <DataTable<ISLAOverride>
      columns={columns}
      data={slaOverrideData}
      title="SLA Overrides Table"
      editable={{
        onRowAdd: (newSLAOverrideData) => addRow(newSLAOverrideData),
        onRowUpdate: (newSLAOverrideData, oldSLAOverrideData) =>
          updateRow(newSLAOverrideData, oldSLAOverrideData),
        onRowDelete: (oldSLAOverrideData) => deleteRow(oldSLAOverrideData),
      }}
      isLoading={isLoading}
      options={{
        showEmptyDataSourceMessage,
        grouping: true,
        columnsButton: true,
      }}
    />
  );
};

export default SLAOverridesTable;
