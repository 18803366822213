import React from 'react';

import InvoicingDownloadWidget from '../InvoicingDownloadWidget/InvoicingDownloadWidget';
import InvoicingDiscrepancyWidget from '../InvoicingDiscrepancyWidget/InvoicingDiscrepancyWidget';

import features from '../../features';

import './InvoicingArea.css';

export interface IInvoicingAreaProps {
  onDownloadSubmit: (
    startDate: string,
    endDate: string,
    customer: string
  ) => Promise<void>;
}

const InvoicingArea = ({
  onDownloadSubmit,
}: IInvoicingAreaProps): JSX.Element => {
  return (
    <div key="invoicing-area" className="invoicing-area">
      <div className="button-container">
        <InvoicingDownloadWidget onSubmit={onDownloadSubmit} />
      </div>
      {features.invoicingDiscrepancyEnabled && <InvoicingDiscrepancyWidget />}
    </div>
  );
};

export default InvoicingArea;
